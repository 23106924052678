#picture-manager {
    position: fixed;
    display: flex;
    z-index: 6;

    top: 60px;
    right: -15vw;

    width: 15vw;
    height: -webkit-fill-available;

    background-color: #222;

    transition: 0.25s all;
}

#picture-manager-content {
    width: 100%;
    padding: 16px;
}
#picture-manager-content > input,
#picture-manager-content > select {
    margin: 10px 0;
}

#picture-manager-result-container {
    overflow-y: scroll;
    width: 65%;
    margin-left: 2px solid #101010;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    gap: 10px;

    height: auto;
}