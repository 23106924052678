@import url('styles/pictureManager.css');

*
{
    margin: 0;
    padding: 0;
	box-sizing: border-box;
}

html,
body
{
	font-family: Helvetica, Arial, sans-serif;
    background-color: #fff;
    color: #333;
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 60px;
    left: 0;
    outline: none;
    z-index:1;
}

.overlayOnWebgl
{
    position: fixed;
    top: 60px;
    left: 0;
    outline: none;
    z-index: 4;
}

#info {
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: left;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 1; /* TODO Solve this in HTML */
}

#control_panel { position: absolute; top: 60px; right: 54px; max-height: 80vh }

.navbar {
    background-color: #101010;
    color: #fff;
    /* border-bottom: 1px solid rgba(255,255,255,0.2); */
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    padding: 0 30px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .navbar a {
    text-decoration: none;
    color: #fff;
  }
  .navbar a.navbar-brand {
    float: left;
    height: inherit;
    line-height: inherit;
    padding: 0 30px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
  }
  .navbar a.navbar-brand span {
    font-size: 28px;
    font-weight: 700;
  }
  .navbar ul {
    float: right;
    list-style: none;
    height: inherit;
    line-height: inherit;
    padding: 0 50px;
  }
  .navbar ul li {
    display: inline-block;
  }
  .navbar ul li a {
    display: block;
    text-align: center;
    position: relative;
    min-width: 120px;
    padding: 0 30px;
    font-size: 14px;
    transition: 0.4s all;
    letter-spacing: 2px;
  }
  .navbar ul li a::before {
    content: "";
    width: 0;
    height: 4px;
    
    position: absolute;

    left: 0;
    bottom: 0;

    background-color: rgb(255, 255, 255);
    transition: width 0.25s ease-out;
  }
  .navbar ul li a:hover {
    background-color: rgba(255,255,255,0.15);
  }
  .navbar ul li a:hover::before {
    width: 100%;
  }
  .navbar .navbar-toggler {
    display: none;
  }

  @media (max-width: 991px) {
    .navbar ul {
      padding: 0 10px;
    }
    .navbar ul li a {
      min-width: 100px;
      padding: 0 20px;
    }
  }
  
  @media (max-width: 767px) {
    .navbar {
      padding: 0;
    }
    .navbar ul {
      width: 100%;
      padding: 0;
      background-color: rgba(0,0,0,0.9);
      /* height: auto; */
      height: 100vh;
      max-height: 0;
      overflow: hidden;
      transition: all ease-in-out 0.3s;
    }
    .navbar ul.open {
      max-height: 100vh;
    }
    .navbar ul li {
      width: 100%;
      /* border-bottom: 1px solid rgba(255,255,255,0.3); */
    }
    .navbar ul li a {
      padding: 0;
    }
    .navbar .navbar-toggler {
      display: block;
      position: absolute;
      height: 40px;
      top: 20px;
      right: 20px;
      background-color: transparent;
      color: #fff;
      border: 3px solid #fff;
      /* border: none; */
      /* border-radius: 4px; */
      outline: none;
      padding: 0 5px;
      cursor: pointer;
    }
    .navbar .navbar-toggler span,
    .navbar .navbar-toggler span::before,
    .navbar .navbar-toggler span::after {
      display: block;
      content: '';
      background-color: #fff;
      height: 3px;
      width: 28px;
      border-radius: 4px;
      transition: all ease-in-out 0.3s;
    }
    .navbar .navbar-toggler span::before {
      transform: translateY(-8px);
    }
    .navbar .navbar-toggler span::after {
      transform: translateY(5px);
    }
    
    .navbar .navbar-toggler.open-navbar-toggler span {
      /* transform: rotate(90deg); */
      background-color: transparent;
      /* transform: scale(0.85) rotate(270deg); */
    }
    .navbar .navbar-toggler.open-navbar-toggler span::before {
      transform: translateY(0px) rotate(45deg);
      /* transform: translateY(0px) scale(0.75) rotate(45deg); */
    }
    .navbar .navbar-toggler.open-navbar-toggler span::after {
      transform: translateY(-3px) rotate(-45deg);
      /* transform: translateY(-3px) scale(0.75) rotate(-45deg); */
    }
  }

  .loading-bar{
    position:fixed;
    z-index: 3;
    top: 50%;
    width: 100%;
    height: 3px;
    background: #ffffff;
    transform: scaleX(0.1);
    transform-origin: top left;
    transition: transform 0.5s;
  }

  .loading-bar.ended
  {
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 0.3s, background 0.3s;
  }

  .loading-background{
    position:fixed;
    z-index: 2;
    top: 0%;
    width: 100%;
    height: 100%;
    background: #757575;
    opacity: 0.5;
    text-align: center;
    line-height: 700px;
    font-size: 24px;
    color:rgb(0, 0, 0);
  }

  .loading-background > t {
    font-size: 12px;
    width: 200px;
    position:absolute;
    text-align: center;
  }

  .loading-background.ended{
    transform: scaleX(0);
  }

  .btn-group {
    position:absolute;
    top: 60px;
    z-index: 10;
    transition: 0.1s;
    height: calc(100vh - 60px);
    /* top: 50%; */
    /* left: 1%; */
    /* border-radius: 10px; */
    background-color: #101010;
    border-right: 1px solid #323232;
    /* overflow: hidden ; */
    /* transform: translateY(-50  %); */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .btn-group > div {
    display: flex;
    flex-direction: column;
    background-clip: content-box;
  }

  .btn-group button {
    margin: 4px;
    position: relative;
  /* left: -50px;  */
    width:52px;
    height:52px;
    background-color: #101010; /* Black background */
    border: 0px solid #fffffffc; /* Green border */
    color: white; /* White text */
    padding: 8px 8px; /* Some padding */
    /* margin-bottom: 20px; */
    cursor: pointer; /* Pointer/hand icon */
     /* Make the buttons appear below each other */
    border-radius: 8px;
  }
  
  .btn-group button:not(:last-child) {
    border-bottom: none; /* Prevent double borders */
  }
  
  /* Add a background color on hover */
  .btn-group button:hover {
    /* left:0px; */
    transition: 0.3s all;
    background-color: #939393;

  }
  .btn-group #scenes-button, .btn-group #collapsepanel-button {
    margin: 4px;
    position: relative;
  /* left: -50px;  */
    width:52px;
    height:52px;
    background-color: #101010; /* Black background */
    border: 0px solid #fffffffc; /* Green border */
    color: white; /* White text */
    padding: 8px 8px; /* Some padding */
    /* margin-bottom: 20px; */
    cursor: pointer; /* Pointer/hand icon */
     /* Make the buttons appear below each other */
    border-radius: 8px;

  }
  .btn-group #scenes-button:hover, .btn-group #collapsepanel-button:hover {
    /* left:0px; */
    transition: 0.3s all;
    background-color: #939393;

  }

  .right-btn-group {
    position:absolute;
    z-index: 5;
    top: 60px;
    transition: 0.25s; 
    /* top: 50%; */
    height: calc(100vh - 40px);
    /* top: 50%; */
    /* left: 1%; */
    /* border-radius: 10px; */
    background-color: #101010;
    border-right: 1px solid #323232;
    right: 0;
    display: flex;
    flex-direction: column;
  }

  .right-btn-group button {
    position: relative;
  /* left: -50px;  */
    transition: 0.1s; 
    width:45px;
    margin: 4px;
    height:45px;
    background-color: #101010; /* Black background */
    border: 0px solid #fffffffc; /* Green border */
    border-radius: 8px;
    color: white; /* White text */
    padding: 10px 10px; /* Some padding */
    /* margin-bottom: 20px; */
    cursor: pointer; /* Pointer/hand icon */
     /* Make the buttons appear below each other */
  }
  
  .right-btn-group button:not(:last-child) {
    border-bottom: none; /* Prevent double borders */
  }
  
  /* Add a background color on hover */
  .right-btn-group button:hover {
    /* left:0px; */
    background-color: #939393;
  }

  /* The sidepanel menu */
.addpanel {
  height: 91vh; /* Specify a height, height < 100vh enables scrolling to bottom */
  width: 300px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 6; /* Stay on top */
  top: 60px;
  left: 60px;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  /* padding-top: 50px; /*Place content 60px from the top */
  transition: 0.25s; /* 0.2 second transition effect to slide in the sidepanel */
}

/* The sidepanel links */
/* .addpanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  transition: 0.3s;
} */

/* When you mouse over the navigation links, change their color */
/* .addpanel a:hover {
  color: #f1f1f1;
} */

/* Position and style the close button (top right corner) */
.addpanel .closebtn {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  /* left:s 100px; */
  font-size: 28px;
  margin-top: 0px; 
  margin-right: 0px;
  z-index: 1;
}

.addpanel .button-container {
  width: 100%;
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  border-bottom: 1px solid #323232;
  margin-bottom: 8px;
}
.listbtn {
  position: relative;
  /* position: -webkit-sticky;
  position: sticky; */
  /* top: 10px;
  left: 10px; */
  margin-top: 0px; 
  /* margin-left: 10px; */
  font-size: 14px;
  padding: 10px;
  width: 100%;
  background-color: #101010;
  /* border: 2px solid rgb(176, 174, 171); */
  border-radius: 6px;
  color: rgb(176, 174, 171);
  font-weight: 600;
  transition: 0.2s all;
  z-index: 1;
}

.listbtn:hover {
  background-color: rgb(176, 174, 171);
  color: #202020;
}

.listbtn--red {
  background-color: rgb(255, 90, 90);
  color: #202020;
}
.listbtn--red:hover {
  background-color: rgb(255, 90, 90);
  color: #202020;
}
.listbtn--green:hover {
  background-color: rgb(90, 90, 255);
  color: #fdfdfd;
}

.addpanel .functionalbtn {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  left: 10px;
  margin-top: 10px; 
  margin-left: 10px;
  font-size: 14px;
  padding: 2px 5px;
  background-color: rgb(207, 206, 206);
  border: 2px solid rgb(83, 83, 83); 
  color: rgb(0, 0, 0);
  transition: 0.1s;
  z-index: 1;
}

 .propNode {
  position: relative;
  top: 10px;
  left: 10px;
  margin-top: 0px; 
  font-size: 16px;
  /* padding: 12px; */
  float: left;
  /* margin-right: 10px;
  margin-bottom: 20px; */
  color: rgb(176, 174, 171);
  transition: 0.3s all;
  background-color: transparent;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;

  cursor: pointer;

  overflow: hidden;
  white-space: nowrap;
  width: 40%;
  /* width: 200px; */
  /* height: 110px; */
}

.propNode > p {
  text-overflow: ellipsis;
  font-size: 12px;
  width: 90px;
  overflow: hidden;
  text-align: center;
}

.propNode > l {
  font-size: 12px;
  width: 90px;
  position:absolute;
  text-align: center;
}

.propNode > img {
  margin: 0 auto;
  border-radius: 10px;
}

.propNode:hover {
  background-color: #939393;
}

.addpanel .propImg{
  position: relative;
  width: 80px;
  height: 80px;
}

/* The sidepanel menu */
.filterpanel {
  height: 100vh; /* Specify a height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 6; /* Stay on top */
  top: 60px;
  left: 60px;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 50px; /* Place content 60px from the top */
  transition: 0.25s; /* 0.2 second transition effect to slide in the sidepanel */
}
  
/* The sidepanel links */
.filterpanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
  
/* When you mouse over the navigation links, change their color */
.filterpanel a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.filterpanel .closebtn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 28px;
  margin-left: 50px;
}

 /* The lightpanel menu */
 .lightpanel {
  height: 100vh; /* Specify a height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 6; /* Stay on top */
  top: 60px;
  left: 60px;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 50px; /* Place content 60px from the top */
  transition: 0.25s; /* 0.2 second transition effect to slide in the sidepanel */
}

/* The sidepanel links */
.lightpanel a {
  /* padding: 8px 8px 8px 32px;
  text-decoration: none; */
  font-size: 15px;
  /* color: #818181;
  display: block;
  transition: 0.3s; */
}

.lightpanel .custombtn {
  position: absolute;
  top: 10px;
  left: 0px;
  font-size: 15px;
  margin-left: 10px;
  background-color: black;
  color: white;
  border: 2px solid gray; /* Green */
}

/* When you mouse over the navigation links, change their color */
/* .lightpanel a:hover {
  color: #f1f1f1;
} */

/* Position and style the close button (top right corner) */
.lightpanel .closebtn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 28px;
  margin-left: 50px;
}

/* Slider to control lighting */
.slidecontainer {
  position:relative;
  top:0;
  left:5%;
  right: 5%;
  width: 90%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 5px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .1s; /* 0.2 seconds transition on hover */
  transition: opacity .1s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 10px; /* Set a specific slider handle width */
  height: 10px; /* Slider handle height */
  background: #04AA6D; /* TODO: Green background? */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #8e8e8e; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

 /* The comic panel */
 .comicpanel {
  height: 100vh; /* Specify a height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 6; /* Stay on top */
  top: 60px;
  right: 0;
  border: 2px solid #000000fc; /* Green border */
  background-color: rgb(255, 255, 255); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 50px; /* Place content 60px from the top */
  transition: 0.25s; /* 0.2 second transition effect to slide in the sidepanel */
}

.comicpanel button {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0px;
  left: 0px;
}

/* The sidepanel links */
.comicpanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.comicpanel a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.comicpanel .closebtn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 28px;
  margin-left: 50px;
}

  /* The sidepanel menu */
  .posepanel {
    overflow-y: scroll;
    /* height: 100vh; Specify a height */
    width: 0px; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 6; /* Stay on top */
    top: 60px;
    left: 0;
    left: 60px;
    bottom: 0;
    background-color: #101010; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 50px; /* Place content 60px from the top */
    transition: 0.25s; /* 0.2 second transition effect to slide in the sidepanel */
  }
  
  /* The sidepanel links */
  .posepanel a {
    /* padding: 8px 8px 8px 32px;
    text-decoration: none; */
    font-size: 15px;
    /* color: #818181;
    display: block;
    transition: 0.1s; */
  }
  
  /* When you mouse over the navigation links, change their color */
  /* .posepanel a:hover {
    color: #f1f1f1;
  } */
  
  /* Position and style the close button (top right corner) */
  .posepanel .closebtn {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 28px;
    margin-left: 50px;
  }

  #captured-image-context-menu {
    position: fixed;
    z-index: 8;
    width: 150px;
    background-color: #111;
    border-radius: 5px;
    transform: scale(0);
    transform-origin: top left;
  }

  #captured-image-context-menu.visible {
    /*display: block;*/
    transform: scale(1);
    transition: transform 200ms ease-in-out;
  }

  #captured-image-context-menu .context-menu-item {
    padding: 8px 10px;
    font-size: 14px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: inherit;
  }

  #captured-image-context-menu .context-menu-item:hover {
    background-color: #939393;
  }
  .posepanel .jointsbtn {
    position: absolute;
    top: 10px;
    left: 0px;
    font-size: 15px;
    margin-left: 10px;
    background-color: black;
    color: white;
    border: 2px solid gray; /* Green */
    }


/* .addpanel, .posepanel, .lightpanel, .filterpanel {
  transition: 0s width;
}

.addpanel, .posepanel, .lightpanel, .filterpanel {
  transition: 0s width;
} */


.app-button {
  position: relative;
  z-index: 10;
}
.app-button::after {
  background-color: #222;
  border: 1px solid #101010;
  border-radius: 2px;
  color: #222;
  content: attr(data-title);
  display: block;
  pointer-events: none;
  font-family: sans-serif;
  font-size: 16px;
  padding: 7px 10px;
  position: absolute;
  top: 50%;
  left: 90%;
  min-width: 100px;
  color: #fdfdfd;
  font-size: 14px;
  opacity: 0;
  transition: 0.2s opacity;
}
.app-button--right:after {
  left: -100px;
  top: 80%;
}
.app-button:hover::after {
  opacity: 1;
}

.app-input {
  padding: 4px 8px;
  font-size: 16px;
  color: #101010;
  z-index: 100;
  width: 100%;
  margin: 10px;
  border-radius: 4px;
  border-width: 0px;
  height: 35px;
}

.app-input:focus {
  outline:none;
}

.app-select {
  margin: 10px;
  font-size: 16px;
  color: #101010;
  width: 100%;
  border-radius: 4px;
  border-width: 0px;
  height: 35px;
}

.app-select > option {
  padding: 4px 8px;
  height: 20px;
}

.folderName { 
  transition: 0.2s all;
  padding: 8px 12px;
  user-select: none;
  cursor: pointer;
  margin: 10px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 18px;
  color: #cdcdcd;
  display: block;
}

.folderName > l {
  font-size: 12px;
  width: 150px;
  position: absolute;
  text-align: right;
  right: 10px;
}

.folderName:hover {
  background-color: #cdcdcd;
  color: #101010;
}

.search-result-title {
  color: #939393;
  font-size: 16px;
  padding: 8px;
  margin: 4px;
}
.search-result-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #fdfdfd;
  padding: 8px;
  padding-left: 12px;
  margin: 4px;
}

.search-result-list a {
  margin-top: 20px;
}

.search-result-title {
  /* float: unset; */
  position: absolute;
}

#delete-object-button {
  margin-top: 20px;
}